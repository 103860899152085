import React, { useContext } from 'react'
import { SelectedModal } from '..'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import { useResendInvitationUser } from '../../../../hooks/api/entreprises'

import { UserEntrepriseType } from '../../../../types/api/entreprise'
import useHandleError from '../../../../hooks/api/useHandleError'
import { ActiveEnterpriseContext } from '../../../../context/activeEnterpriseContext'
interface PropsActionColumn {
  user: UserEntrepriseType
  setSelectedUser: React.Dispatch<React.SetStateAction<UserEntrepriseType | undefined>>
  handleOpenModal: (selectedModal: SelectedModal) => void
  hasSMultipleMain :boolean
}
import { t } from 'i18next'
import ThreeDotsIcon from '../../../../icons/threeDotsIcon'
const ActionColumn = ({ user, setSelectedUser, handleOpenModal, hasSMultipleMain }: PropsActionColumn) => {
  const { activeEnterprise } = useContext(ActiveEnterpriseContext)

  const { mutate: resendInvitationUser, error: errorResendInvitationUse } =
    useResendInvitationUser()
  useHandleError(errorResendInvitationUse)

  const [selectedMenu, setSelectedMenu] = React.useState<null | HTMLElement>(null)
  const open = Boolean(selectedMenu)
  const handleCloseMenu = () => setSelectedMenu(null)

  const handleClickDelete = () => {
    handleCloseMenu()
    handleOpenModal('confirmDeleteUser')
    setSelectedUser({ ...user })
  }
  const handleClickResentInv = () => {
    setSelectedUser({ ...user })
    const data = {
      user_crm_id: user.crm_id,
      company_crm_id: activeEnterprise.crm_id,
    }
    handleCloseMenu()
    resendInvitationUser(data, {
      onSuccess: async () => {
        handleOpenModal('confirmResendInvitation')
      },
    })
  }
  const handleClickEdit = () => {
    handleCloseMenu()
    setSelectedUser({ ...user })
    handleOpenModal('updateUser')
  }

  return (
    <Box sx={{ textAlign: 'center', pr: '10px' }}>
      <IconButton
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        color='secondary'
        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
          setSelectedMenu(event.currentTarget)
        }
        sx={{
          pt: '0px',
          '&:hover': {
            backgroundColor: 'white',
          },
        }}
      >
        <ThreeDotsIcon />
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={selectedMenu}
        open={open}
        onClose={handleCloseMenu}
        anchorPosition={{ top: 0, left: 0 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: -50,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 100,
        }}
        sx={{
          ul: {
            display: 'flex',
            flexDirection: 'column',
            gap: '14px',
          },
          li: {
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '20px',
            marginTop: '0 !important',
          },
        }}
      >
        {(user.status === 'En_attente' || user.status === 'Refuse') && (
          <MenuItem onClick={handleClickResentInv}> {t('common.actions.resenInvit')}</MenuItem>
        )}

        {user.status === 'Actif' && (
          <Box 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '14px',
          }}
          style={{gap: '14px'}}>
            <MenuItem onClick={handleClickEdit}> {t('common.actions.modify')}</MenuItem>
            <MenuItem onClick={handleClickDelete} disabled={user.is_main && !hasSMultipleMain} >{t('common.actions.pullFromEtr')}</MenuItem>
          </Box>
        )}
      </Menu>
    </Box>
  )
}

export default ActionColumn
