// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useContext } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Title from '../components/common/title'
import useSelectedItemSideBar from '../hooks/useSelectedItemSideBar'
import LinkButton from '../components/common/buttons/linkButton'
import CustomExternalLink from '../components/common/customExternalLink'
import CustomDropdown from '../components/common/customDropdown'
import LiveHelpIcon from '../icons/liveHelpIcon'
import CustomButton from '../components/common/buttons/customButton'
import { ActiveEnterpriseContext } from '../context/activeEnterpriseContext'
import Loader from '../components/common/loader'
import { ActiveRegionContext } from '../context/activeRegionContext'
import {
  useGetMemberBenefits,
} from '../hooks/api/memberBenefits'
import LinkIcon from '../icons/linkIcon'

const MemberBenefits = () => {
  useSelectedItemSideBar('memberBenefits')
  const { activeRegion } = useContext(ActiveRegionContext)
  const { activeEnterprise } = useContext(ActiveEnterpriseContext)
  const { data: benefits, isFetching } = useGetMemberBenefits(activeEnterprise?.crm_id)
  return (
    <>
      <Title> Avantages Membres</Title>
      <Typography mb='24px!important'>
        En tant que membre, votre ACQ régionale vous offre de nombreux services. Certains de ces
        services sont inclus dans votre membership, d’autres sont offerts à un coût additionnel. Si
        vous n’en bénéficiez pas encore, contactez votre association régionale pour les activer.
        Consultez aussi les sections{' '}
        <CustomExternalLink
          href={`${process.env.REACT_APP_ACQ_SITE}/entrepreneurs/services-aux-membres/`}
        >
          Services
        </CustomExternalLink>{' '}
        et{' '}
        <CustomExternalLink
          href={`${process.env.REACT_APP_ACQ_SITE}/entrepreneurs/services-aux-membres/rabais-sur-achat/`}
        >
          Rabais sur achat
        </CustomExternalLink>{' '}
        sur notre site Web pour de plus amples détails.
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px', mb: '48px' }}>
        <LinkButton href={`${process.env.REACT_APP_ACQ_SITE}/entrepreneurs/services-aux-membres/`}>
          voir les services
        </LinkButton>
        <LinkButton
          href={`${process.env.REACT_APP_ACQ_SITE}/entrepreneurs/services-aux-membres/rabais-sur-achat/`}
        >
          voir les rabais aux membres
        </LinkButton>
      </Box>
      {isFetching ? 
          <Loader></Loader>
      : 
        <>  
              {benefits?.include_services && benefits?.include_services.length > 0?
              <>
              <Typography
                variant='h5'
                textTransform='uppercase'
                sx={{ pb: '26px', borderBottom: '1px solid #DADCDD' }}
              >
                Services inclus dans votre membership
              </Typography>
              {benefits?.include_services?.map((item)=> {
                    return (
                    <CustomDropdown title={item.title} key={item.title} >
                        <Typography width='80%'>
                        {item.description}
                        </Typography>
                        {item?.link ? 
                        <CustomExternalLink
                        href={item?.link}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '15px',
                          gap: '8px',
                          '& svg path': {
                            fill: '#444D4E',
                          },
                        }}
                      >
                        <span>En savoir plus</span>
                        <LinkIcon />
                      </CustomExternalLink> : null
                      }
                    </CustomDropdown>
                    )
              })}
              </> 
              :null } 
              { benefits?.request_services && benefits?.request_services.length > 0?
                <>
                  <Typography variant='h5' textTransform='uppercase' sx={{ mt: '48px', mb: '26px' }}
                >
                  Services payants sur demande
                </Typography>
                {benefits?.request_services?.map((item)=> {
                      return (<CustomDropdown title={item.title} key={item.title} >
                        <Typography width='80%'>
                        {item.description}
                        </Typography>
                        {item?.link ?
                        
                        <CustomExternalLink
                        href={item?.link}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '15px',
                          gap: '8px',
                          '& svg path': {
                            fill: '#444D4E',
                          },
                        }}
                      >
                        <span>En savoir plus</span>
                        <LinkIcon />
                      </CustomExternalLink>
                        
                        : null}
                         
                      </CustomDropdown>)
                })}
                </> 
                :null  } 
          </>
      }

      <Box
        sx={{
          border: '1px solid #444D4E',
          p: '40px',
          display: 'flex',
          alignItems: 'center',
          mt: '51px',
        }}
      >
        <LiveHelpIcon />
        <Box
          sx={{
            ml: '5%',
            mr: '8%',
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          <Typography variant='h3' textTransform='uppercase'>
            BESOIN D’AIDE ?
          </Typography>
          <Typography>
            Contacter nos experts pour adhérer aux services sur demande et pour toutes questions
            concernants nos services.
          </Typography>
        </Box>
        <CustomButton
          variant='principal'
          href={`${process.env.REACT_APP_JOIN_US}?regionid=${activeRegion}`}
          sx={{
            py: '11px',
            px: '30px',
            fontSize: '15px',
          }}
        >
          NOUS JOINDRE
        </CustomButton>
      </Box>
    </>
  )
}

export default MemberBenefits
