import { path, is, isEmpty } from 'ramda'
import { t } from 'i18next'
import { LocalStorageKeys, provinces, uniqueKey, listOrdersType } from '../constants'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import { ItemHasChildrenType, ItemType, Option, Phone, Role } from '../types'
import { Member } from '../types/api/user'
import { FormikProps } from 'formik'
import { ErrorApiFieldType, OptionalParamsApi } from '../types/api'
import { v4 as uuidv4 } from 'uuid'

export const getNestedObject = (keyPath: string, obj: Record<string, any>) =>
  path(keyPath.split('.'), obj)
export const isString = (value: any): value is string => is(String, value)
export const getProvinces = (): Option[] =>
  provinces.map((province) => ({ id: province, name: t(`provinces.${province}`) }))
export const getDay = (date: string) => (date ? dayjs(date).format('DD') : '')
export const getMonthName = (date: string) => {
  dayjs.locale('fr')
  return date ? dayjs(date).format('MMMM') : ''
}
export const getYear = (date: string) => (date ? dayjs(date).format('YYYY') : '')
export const getDayAndMonthName = (date: string) => `${getDay(date)} ${getMonthName(date)}`
export const getDayAndMonthNameYear = (date: string) =>
  `${getDay(date)} ${getMonthName(date)} ${getYear(date)}`
export const getFormatDate = (date: string) => (date ? dayjs(date).format('YYYY-MM-DD') : '')

const monthNames = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
const formatDate = (date : string, text:string) => {
  if (!text) return date;
  const format1 = date.match(/(\d{2})-(\d{2})-(\d{4})/);
  if (format1) {
      text = text.replace('Y', format1[3]);
      text = text.replace('m', format1[2]);
      text = text.replace('M', monthNames[Number(format1[2]) - 1]);
      text = text.replace('d', format1[1]);
      return text;
  }
  const format2 = date.match(/(\d{4})-(\d{2})-(\d{2})/);
  if (format2) {
      text = text.replace('Y', format2[1]);
      text = text.replace('m', format2[2]);
      text = text.replace('M', monthNames[Number(format2[2]) - 1]);
      text = text.replace('d', format2[3]);
      return text;
  }
}
export const getDate = (start : any, end : any) => {
  end = end ? end : start;
  const result = formatDate(end, 'd M Y');
  if (result == formatDate(start, 'd M Y')) {
      return result;
  } else {
      if (formatDate(start, 'M Y') == formatDate(end, 'M Y')) {
          return formatDate(start, 'd') + ' au ' + result;
      } else {
          if (formatDate(start, 'Y') == formatDate(end, 'Y')) {
              return formatDate(start, 'd M') + ' au ' + result;
          } else {
              return formatDate(start, 'd M Y') + ' au ' + result;
          }
      }
  }
}



export const formatNumber = (number: string) =>
  parseInt(number)
    .toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace(/,/g, ' ')
export const extractMainPhoneAndOthers = (phones: Phone[]) => ({
  mainPhone: phones?.[0] || [],
  otherPhones: phones?.slice(1) || [],
})
export const getTitle = (title: string, listTitles: Option[] | undefined) =>
  Array.isArray(listTitles) && listTitles?.length > 0
    ? listTitles?.find((option) => option.id === title)?.name
    : ''
export const getActiveEnterpriseFromLocalStorage = () => {
  const activeEnterprise = localStorage.getItem(LocalStorageKeys.ActiveEnterprise)
  return (activeEnterprise ? JSON.parse(activeEnterprise) : {}) as Member
}
export const hasChildren = (item: ItemType | ItemHasChildrenType): item is ItemHasChildrenType =>
  'children' in item

export const isMember = (activeEnterprise: Member) => !isEmpty(activeEnterprise)
export const isAnyRoleGranted = (
  activesEntrepriseRoles: Role[] | null,
  rolesGranted: Role[] | null,
) => {
  if (rolesGranted === null) return true
  if (activesEntrepriseRoles === null) return false
  return activesEntrepriseRoles.some((role) => rolesGranted?.includes(role))
}
export const hasAccess = (rolesGranted: null | Role[], activeEnterprise: Member) => {
  let nonMemberAccess = false

  if (rolesGranted === null) {
    nonMemberAccess = true
  }
  else {
    nonMemberAccess = activeEnterprise.legal_name === undefined && rolesGranted.includes('Non-member')
  }

  const nonMemberHasAccess = nonMemberAccess
  return (
    nonMemberHasAccess ||
    (isMember(activeEnterprise) && isAnyRoleGranted(activeEnterprise.roles, rolesGranted))
  )
}
export const handleCustomErrorsFormik = (formik: FormikProps<any>, errors: ErrorApiFieldType) => {
  Object.entries(errors).forEach(([key, value]) => {
    formik.setFieldError(key, value[0])
  })
}

const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
  input !== null && input.tagName === 'IFRAME'

export function postCrossDomainMessage(msg: string) {
  const frame = document.getElementById('ifr')
  if (isIFrame(frame) && frame.contentWindow) {
    frame.contentWindow.postMessage(msg, `${process.env.REACT_APP_ACQ_SITE as string}/cross.php`)
  }
}
export const generateParamsURL = ({ member_crm_id, page, sort, filter }: OptionalParamsApi) => {
  const params = new URLSearchParams({
    ...(member_crm_id ? { member_crm_id } : {}),
    page_size: '10',
    ...(page ? { page } : {}),
    sort_by: sort.id,
    asc: sort.desc ? '0' : '1',
  })
  if (filter)
    Object.entries(filter).forEach(([key, value]) => {
      params.set(key, value)
    })
  return params.toString()
}
export const addUniqueKeyToList = <T>(list: T[] | undefined | null) => {
  if (!list) return []
  return list.map((item: T) => {
    return { ...item, [uniqueKey]: uuidv4() }
  })
}

export const getOrderType = (type: string) => {
 return listOrdersType.filter(item => {
  return item.id === type
 })[0]?.name
}
