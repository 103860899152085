import Axios from '.'
import { AxiosConfigType } from '../types'
import { OptionalParamsApi, PaginationResponse, PaginationResponseSecondFormat } from '../types/api'
import {
  GeneralDataType,
  MembershipType,
  UpdateGeneralDataType,
  ContactType,
  ContactUpdateType,
  ContactCreateType,
  UserEntrepriseType,
  UserEntrepriseUpdateType,
  UsersEnterpriseCreateType,
  UserResendInvitationType,
  UserRemoveType,
  CommunicationType,
  DocumentType,
  OrderType,
  UserEntrepriseTable
} from '../types/api/entreprise'
import {
  FormationType
} from '../types/api/formations'
import { generateParamsURL } from '../utils'

const basePath = '/companies'
const getGeneralData = async (idEntreprise: string | undefined, config?: AxiosConfigType) => {
  const response = await Axios.get<GeneralDataType>(
    `${basePath}/${idEntreprise}/profile/general`,
    config,
  )
  return response.data
}
const updateGeneralData = async (
  updatedData: { id: string; data: UpdateGeneralDataType },
  config?: AxiosConfigType,
) => {
  const { id, data } = updatedData
  const response = await Axios.put<any>(`${basePath}/${id}/profile/general`, data, config)
  return response.data
}

const getContacts = async (id: string, config?: AxiosConfigType) => {
  const response = await Axios.get<ContactType[]>(`${basePath}/${id}/profile/contacts`, config)
  return response.data
}
const updateContact = async (data: ContactUpdateType, config?: AxiosConfigType) => {
  const { crm_company_id, crm_contact_id, ...contact } = data
  const response = await Axios.put<ContactType[]>(
    `${basePath}/${crm_company_id}/profile/contacts/${crm_contact_id}`,
    contact,
    config,
  )
  return response.data
}

const getMembership = async (crm_id: string, config?: AxiosConfigType) => {
  const response = await Axios.get<MembershipType>(
    `${basePath}/${crm_id}/profile/membership`,
    config,
  )
  return response.data
}

const createContact = async (data: ContactCreateType, config?: AxiosConfigType) => {
  const { crm_id, ...contact } = data
  return await Axios.post<ContactType>(`${basePath}/${crm_id}/profile/contacts`, contact, config)
}

const getUsersList = async (crm_id: string, config?: AxiosConfigType) => {
  const response = await Axios.get<UserEntrepriseTable>(`${basePath}/${crm_id}/users`, config)
  return response.data
}
const getCommunications = async (
  crm_id: string,
  params: OptionalParamsApi,
  config?: AxiosConfigType,
) => {
  const response = await Axios.get<PaginationResponse<CommunicationType>>(
    `${basePath}/${crm_id}/notes?${generateParamsURL(params)}`,
    config,
  )
  return response.data
}

const getOrders = async (
  params: OptionalParamsApi,
  config?: AxiosConfigType,
) => {
  const response = await Axios.get<PaginationResponseSecondFormat<OrderType>>(
    `/orders?${generateParamsURL(params)}`,
    config,
  )
  return response.data
}
const getDocumentsEnterprise = async (
  crm_id: string,
  params: OptionalParamsApi,
  config?: AxiosConfigType,
) => {
  const response = await Axios.get<PaginationResponse<DocumentType>>(
    `${basePath}/${crm_id}/documents?${generateParamsURL(params)}`,
    config,
  )
  return response.data
}

const getOrdersEntreprise = async (
  crm_id: string,
  params: OptionalParamsApi,
  config?: AxiosConfigType,
) => {
  const response = await Axios.get<PaginationResponse<OrderType>>(
    `/orders?member_crm_id=${crm_id}$/{generateParamsURL(params)}`,
    config,
  )
  return response.data
}

const getDocumentsACQ = async (
  crm_id: string,
  params: OptionalParamsApi,
  config?: AxiosConfigType,
) => {
  const response = await Axios.get<DocumentType[]>(
    `${basePath}/${crm_id}/documents/acq?${generateParamsURL(params)}`,
    config,
  )
  return response.data
}
const readNote = async (crm_id: string, config?: AxiosConfigType) => {
  const response = await Axios.post<PaginationResponse<CommunicationType>>(
    `${basePath}/note/${crm_id}/read`,
    {},
    config,
  )
 return response.data
}
const createUsers = async (data: UsersEnterpriseCreateType, config?: AxiosConfigType) => {
  const { crm_id, ...users } = data
  return await Axios.post<ContactType>(`${basePath}/${crm_id}/users`, users, config)
}

const deleteUser = async (data: UserRemoveType, config?: AxiosConfigType) => {
  const { user_role_crm_id } = data
  const response = await Axios.delete<UserEntrepriseType[]>(
    `${basePath}/user-role/${user_role_crm_id}`,
    config,
  )
  return response.data
}

const updateUser = async (data: UserEntrepriseUpdateType, config?: AxiosConfigType) => {
  const { crm_company_id, crm_id, ...rest } = data
  const response = await Axios.put<UserEntrepriseType[]>(
    `${basePath}/${crm_company_id}/users/${crm_id}`,
    rest,
    config,
  )
  return response.data
}

const resendInvitationUser = async (data: UserResendInvitationType, config?: AxiosConfigType) => {
  const { user_crm_id, company_crm_id } = data
  const response = await Axios.post<UserEntrepriseType[]>(
    `${basePath}/${company_crm_id}/users/${user_crm_id}/invitation/resend`,
    {},
    config,
  )
  return response.data
}

const getNextFormationsEntreprise = async (
  params: OptionalParamsApi,
  config?: AxiosConfigType,
) => {
  const response = await Axios.get<PaginationResponseSecondFormat<FormationType>>(
    `/trainings/purchased?${generateParamsURL(params)}`,
    config,
  )
  return response.data
}


const EntrepriseApi = {
  getGeneralData,
  getMembership,
  updateGeneralData,
  getContacts,
  updateContact,
  createContact,
  getUsersList,
  deleteUser,
  updateUser,
  createUsers,
  resendInvitationUser,
  getCommunications,
  readNote,
  getDocumentsEnterprise,
  getDocumentsACQ,
  getOrdersEntreprise,
  getOrders,
  getNextFormationsEntreprise
}

export default EntrepriseApi
