import { useContext } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ShoppingCartIcon from '../../../icons/shoppingCartIcon'
import { ActiveRegionContext } from '../../../context/activeRegionContext'

interface Props {
  content?:  null | string | number
}
const ShoppingCart = ({content}: Props) => {
  const { activeRegion } = useContext(ActiveRegionContext)
  const cartUrl = content && Number(content) > 0 ? `${process.env.REACT_APP_CART as string}?regionid=${activeRegion}` : '';
  return (
    <Box
      component='a'
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '88px',
        color: 'secondary.main',
        backgroundColor: 'primary.main',
        height: '54px',
        my: '-15px',
        textDecoration: 'none',
      }}
      href={cartUrl}
    >
      <ShoppingCartIcon />
      <Typography variant='h6' sx={{ color: 'secondary.main', mb: '-7px', ml: '3px' }}>
        ({(content && content !== null) ? content : 0})
      </Typography>
    </Box>
  )
}

export default ShoppingCart
