
import {useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { t } from 'i18next'
import BlockTitleText from '../../components/common/blockTitleText'
import OrderItem from '../../components/common/orderItem'
import OrderResume from '../../components/common/orderResume'
import {  useGetOrdersQuery } from '../../hooks/api/order'
import { OrderDetailsType } from '../../types/api/order'
import LoaderPage from '../../components/common/loaderPage';
import { useState, useEffect } from 'react'
import {getDate} from '../../utils'
const Order = () => {

  const [order , setOrder] = useState<OrderDetailsType>({} as OrderDetailsType);
  const { id } = useParams();

  const { data, isFetching } = useGetOrdersQuery(id)

  const isMember = () => {
    return order?.user?.member_name ? true : false
  }
  const isChargedAccount = () => {
    return order?.payment_method === 'Porté au compte' ? true : false
  }
  const isBankTransfer = () => {
    return order?.payment_method === 'Transfert bancaire' ? true : false
  }
  const isFree = () => {
    return parseInt(order?.total, 10) === 0  
  }
  
  useEffect(() => {
    if (data) {
      setOrder(data)
    }
  }, [data])

    if (isFetching) {
      return  <LoaderPage></LoaderPage>;
    }

    return (
        <Grid sx={{ display:'flex', flexDirection:'column',justifyContent:'space-between',  gap:'60px'}} >
          <Grid>
            <Grid sx={{ mb: '80px', borderBottom: '1px solid #DADCDD', paddingBottom: '10px' }} className='block_header_order'>
            <Stack direction='row' spacing={2} justifyContent='space-between' alignItems='flex-start' sx={{
              '& img': { width :'163px'}
            }}>
              <Typography variant='h2' sx={{ textTransform: 'uppercase', mb: '24px'}}>
              Facture {order?.order_number}
              </Typography>
              
              {order?.region?.logo && 
                <img src={`data:image/${order?.region?.extension};base64,${order?.region?.logo}`}  loading="lazy"/>
              }
            </Stack>
            <Grid container>
              <Grid item xs={12} sm={9} sx={{ paddingRight: '20px' }}>
                <Typography>{order?.date_entered}</Typography>
              </Grid>
            </Grid>
            </Grid>
            <Grid container  justifyContent='space-between' alignItems='flex-start'>
              <Grid item xs={4} sm={3} sx={{ padding: '20px', border: '1px solid #DADCDD' }}>
              {(isMember() && (isChargedAccount() || isBankTransfer() )) ? 
                <>
                    <BlockTitleText title='Facturé à' textDesc={`${order?.user?.member_name}`}/> 
                    <Box sx={{paddingBottom: '24px',display: 'flex',flexDirection: 'column',gap: '5px',}}>
                    {order?.member?.address_number || order?.member?.address_street || order?.member?.address_city 
                    || order?.member?.address_postalcode || order?.member?.address_state
                    ?
                    <>
                      <Typography variant='textBold'>
                          Coordonnées
                      </Typography>
                      <Typography variant='smallTextNormal'>
                        {order?.member?.address_number} {order?.member?.address_street} {order?.member?.address_postalcode} 
                        {order?.member?.address_city ? `, ${order?.member?.address_city}` : null}
                        {order?.member?.address_state ? `, ${order?.member?.address_state}` : null}
                      </Typography>
                    </>
                    
                    : null
                    
                    }
                      
                      
                      {order?.user?.member_number ?
                          <Typography variant='smallTextNormal'>
                          <a style={{ color: '#444D4E'}}
                          href={`tel:${order?.user?.member_number}`}>{order?.user?.phone_number}</a>
                          </Typography> 
                      : null
                      }
                      
                    </Box>
                    <BlockTitleText title='No de membre' textDesc={`${order?.user?.member_number}`}/>
                    <Box sx={{paddingBottom: '24px',display: 'flex',flexDirection: 'column',gap: '5px', borderTop: '1px solid #DADCDD', paddingTop:'24px' }}>
                        <Typography variant='textBold'>
                            Acheteur
                        </Typography>
                        <Typography variant='smallTextNormal'>
                          {`${order?.user?.first_name} ${order?.user?.last_name}`}
                        {order?.region?.office ? 
                            `, ${order?.region?.office}` : null
                          }
                        </Typography>
                        <Typography variant='smallTextNormal'>
                          <a style={{ color: '#444D4E'}} href={`mailto:${order?.user?.email}`}>{order?.user?.email}</a>
                        </Typography>
                        <Typography variant='smallTextNormal'>
                          <a style={{ color: '#444D4E'}} href={`tel:${order?.user?.phone_number}`}>{order?.user?.phone_number}</a>
                        </Typography>
                      </Box>
                </>
                : (
                    <>
                    <BlockTitleText title='Facturé à' textDesc={`${order?.user?.first_name} ${order?.user?.last_name}`}/> 
                    <Box sx={{paddingBottom: '24px',display: 'flex',flexDirection: 'column',gap: '5px',}}>
                        <Typography variant='textBold'>
                            Coordonnées
                        </Typography>
                        <Typography variant='smallTextNormal'>
                         {order?.user?.address_number} {order?.user?.address_street} {order?.user?.address_postalcode} 
                        {order?.user?.address_city ? `, ${order?.user?.address_city}` : null}
                        {order?.user?.address_state ? `, ${order?.user?.address_state}` : null}
                        </Typography>
                        <Typography variant='smallTextNormal'>
                            <a style={{ color: '#444D4E'}} href={`mailto:${order?.user?.email}`}>{order?.user?.email}</a>
                        </Typography>
                        <Typography variant='smallTextNormal'>
                          <a style={{ color: '#444D4E'}}
                          href={`tel:${order?.user?.phone_number}`}>{order?.user?.phone_number}</a>
                        </Typography>
                      </Box>
                    </>
                ) 
              }
             {isMember() && !isChargedAccount() && !isBankTransfer() ?
                  <>
                      <BlockTitleText title='Entreprise' textDesc={order?.user?.member_name} style={{ borderTop: '1px solid #DADCDD', paddingTop:'24px' }}/>
                      <BlockTitleText title='No de membre' textDesc={`${order?.user?.member_number}`}/>
                  </>
                : null
              }

              { !isFree()  ? 
                  (isChargedAccount() || isBankTransfer() ) ?
                    <>
                    {order?.payment_due_date ? 
                          <Box sx={{paddingBottom: '24px',display: 'flex',flexDirection: 'column',gap: '5px'}}>
                              <Typography variant='textBold'>
                                Date d'échéance
                              </Typography>
                              <Typography variant='bodySmall'>
                                  {getDate(order?.payment_due_date, null)}
                              </Typography>
                          </Box> 
                          : null
                    }
                    {order?.region?.payment_terms ? 
                          <Box sx={{paddingBottom: '24px',display: 'flex',flexDirection: 'column',gap: '5px',}}>
                              <Typography variant='textBold'>
                              Modalité de paiement
                              </Typography>
                              <Typography variant='bodySmall'>
                                  {order?.region?.payment_terms}
                              </Typography>
                          </Box> 
                          : null
                    }
                    {isBankTransfer() && order?.region?.payment_terms_bank_transfer ? 
                          <Box sx={{paddingBottom: '24px',display: 'flex',flexDirection: 'column',gap: '5px'}}>
                              <Typography variant='textBold'>
                                Modalité de paiement par transfert bancaire
                              </Typography>
                              <Typography variant='bodySmall'>
                                  {order?.region?.payment_terms_bank_transfer}
                              </Typography>
                          </Box> 
                          : null
                    }
                    {isChargedAccount() && order?.region?.payment_terms_charged_to_account ? 
                          <Box sx={{paddingBottom: '24px',display: 'flex',flexDirection: 'column',gap: '5px'}}>
                              <Typography variant='textBold'>
                              Modalité de paiement portée au compte
                              </Typography>
                              <Typography variant='bodySmall'>
                                  {order?.region?.payment_terms_charged_to_account}
                              </Typography>
                          </Box> 
                          : null
                    }
                    </>
                  : 
                  <>
                  <BlockTitleText style={{'wordBreak': 'break-all'}} title='No de transaction' textDesc={order?.transaction_id}/>
                  <BlockTitleText title='Mode de paiement' textDesc={order?.payment_method} style={{ borderTop: '1px solid #DADCDD', paddingTop:'24px' }}/>
                  </>
            : null}
            
              </Grid>


              <Grid item xs={7} sm={8} className='block_details_order' sx={{ paddingRight: '20px' }}>
                <Box>
                  <Typography variant='h4'> 
                    Détails
                  </Typography>
                  {order?.products?.map((product)=> {
                    return (
                      <div key={product.item_id} >
                          <OrderItem  
                          product={product} 
                          isMember = {order?.user?.member_name}
                          />
                      </div>
                    )
                  })}
                
                  <OrderResume
                  subtotal = {order?.subtotal}
                  discount = {order?.discount}
                  code_promo_value = {order?.code_promo_value}
                  subtotal_with_discount = {order?.subtotal_with_discount}
                  tps = {order?.tps}
                  tpsNumber = {order?.region?.tps_number}
                  tvqNumber = {order?.region?.tvq_number}
                  tvq = {order?.tvq}
                  total = {order?.total}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{ pt: '24px', borderTop: '1px solid #DADCDD', paddingBottom: '10px', display:'flex', justifyContent:'space-between',  gap:'20px'}}>
                <Box sx={{ width: '50%'}}>
                      <Typography variant='h5' sx={{ mb: '5px'}}>
                        {`ACQ ${order?.region?.name}`} 
                      </Typography>
                      <Typography variant='body1' sx={{ mb: '5px'}}>
                        {`ACQ ${order?.region?.address_number}, ${order?.region?.address_street}`}
                        {order?.region?.office ? 
                          `, ${order?.region?.office}` : null
                        }
                      </Typography>
                        {order?.region?.province ?
                            <Typography variant='body1'>
                            {t(`provinces.${order?.region?.province}`)} {`, ${order?.region?.postal_code}`}
                            </Typography>
                         : null }
                     
                </Box>
                {
                  order?.region?.support_title && order?.region?.support_text ?
                    (
                      <Box sx={{ width: '50%', alignItems: 'flex-end', display: 'flex', flexDirection: 'column'}}>
                          <Typography variant='h5' sx={{ mb: '5px'}}>
                                  {`${order?.region?.support_title}`} 
                          </Typography>
                          <Typography variant='body1'>
                            {`${order?.region?.support_text}`}
                          </Typography>
                      </Box>
                    ) : null
                }
          </Grid>
      </Grid>


    )
} 

export default Order;