import React from 'react'
import Typography from '@mui/material/Typography'
import { SxProps, Theme } from '@mui/material'
interface Props {
  href: string
  children: React.ReactNode
  sx?: SxProps<Theme>
}
const CustomExternalLink = ({ href, children, sx }: Props) => {
  let currentHref = '';

  if (!href.includes('http') && href.includes('www')) {
    currentHref = `https://${href}`;
  } else if (!href.includes('http') && !href.includes('www') && href.includes('.')) {
    currentHref = `https://${href}`;
  } else {
    currentHref = href;
  }

  return (
    <Typography
      component='a'
      sx={{ textDecoration: 'underline', fontWeight: '500', cursor: 'pointer', ...sx }}
      href={currentHref}
      target='_blank'
      rel="noopener noreferrer"
    >
      {children}
    </Typography>
  )
}

export default CustomExternalLink
