import EntrepriseApi from '../../api/entrepriseApi'
import {
  GeneralDataType,
  MembershipType,
  ContactType,
  UserEntrepriseType,
  CommunicationType,
  DocumentType,
  OrderType,
  UserEntrepriseTable
} from '../../types/api/entreprise'
import {
  FormationType
} from '../../types/api/formations'
import useCustomQuery from './useCustomQuery'
import useCustomMutation from './useCustomMutation'
import { FormikProps } from 'formik'
import useCustomMutationWithFormik from '../useCustomMutationWithFormik'
import { OptionalParamsApi, OptionsTypeReactQuery, PaginationResponse, PaginationResponseSecondFormat } from '../../types/api'

export enum EnterprisesServerStateKeysEnum {
  GeneralData = 'generalData',
  Membership = 'membership',
  Contacts = 'contacts',
  Users = 'enterprise_users',
  Communications = 'communications',
  DocumentsEnterprise = 'documentsEnterprise',
  DocumentsACQ = 'documentsACQ',
  Orders = 'orders',
  Formations = 'formations',
}

export const useGetGeneralData = (
  idEntreprise: string,
  options?: OptionsTypeReactQuery<GeneralDataType>,
) => {
  return useCustomQuery<GeneralDataType, Error>(
    [EnterprisesServerStateKeysEnum.GeneralData, idEntreprise],
    (header) => EntrepriseApi.getGeneralData(idEntreprise, header),
    options,
  )
}

export const useUpdateGeneralData = (formik: FormikProps<any>) =>
  useCustomMutationWithFormik(EntrepriseApi.updateGeneralData, formik)
export const useGetMembershipData = (
  crm_id: string,
  options?: OptionsTypeReactQuery<MembershipType>,
) =>
  useCustomQuery<MembershipType, Error>(
    [EnterprisesServerStateKeysEnum.Membership, crm_id],
    (header) => EntrepriseApi.getMembership(crm_id, header),
    options,
  )
export const useGetContacts = (crm_id: string, options?: OptionsTypeReactQuery<ContactType[]>) =>
  useCustomQuery<ContactType[], Error>(
    [EnterprisesServerStateKeysEnum.Contacts, crm_id],
    (header) => EntrepriseApi.getContacts(crm_id, header),
    options,
  )

export const useUpdateContact = (formik: FormikProps<any>) =>
  useCustomMutationWithFormik(EntrepriseApi.updateContact, formik)

export const useCreateContact = (formik: FormikProps<any>) =>
  useCustomMutationWithFormik(EntrepriseApi.createContact, formik)

export const useGetUsers = (
  crm_id: string,
  options?: OptionsTypeReactQuery<UserEntrepriseTable>,
) =>
  useCustomQuery<UserEntrepriseTable, Error>(
    [EnterprisesServerStateKeysEnum.Users, crm_id],
    async (header) => await EntrepriseApi.getUsersList(crm_id, header),
    options,
  )
export const useGetCommunicationsQuery = (
  crm_id: string,
  params: OptionalParamsApi,
  options?: OptionsTypeReactQuery<PaginationResponse<CommunicationType>>,
) =>
  useCustomQuery<PaginationResponse<CommunicationType>, Error>(
    [EnterprisesServerStateKeysEnum.Communications, crm_id, params],
    async (header) => await EntrepriseApi.getCommunications(crm_id, params, header),
    options,
  )
  export const useGetOrdersQuery = (
    params: OptionalParamsApi,
    options?: OptionsTypeReactQuery<PaginationResponseSecondFormat<OrderType>>,
  ) =>
    useCustomQuery<PaginationResponseSecondFormat<OrderType>, Error>(
      [EnterprisesServerStateKeysEnum.Orders, params],
      async (header) => await EntrepriseApi.getOrders( params, header),
      options,
    )
export const useGetDocumentsEnterpriseQuery = (
  crm_id: string,
  params: OptionalParamsApi,
  options?: OptionsTypeReactQuery<PaginationResponse<DocumentType>>,
) =>
  useCustomQuery<PaginationResponse<DocumentType>, Error>(
    [EnterprisesServerStateKeysEnum.DocumentsEnterprise, crm_id, params],
    async (header) => await EntrepriseApi.getDocumentsEnterprise(crm_id, params, header),
    options,
  )
export const useGetDocumentsACQQuery = (
  crm_id: string,
  params: OptionalParamsApi,
  options?: OptionsTypeReactQuery<DocumentType[]>,
) =>
  useCustomQuery<DocumentType[], Error>(
    [EnterprisesServerStateKeysEnum.DocumentsACQ, crm_id, params],
    async (header) => await EntrepriseApi.getDocumentsACQ(crm_id, params, header),
    options,
  )
export const useReadNote = (options?: any) => useCustomMutation(EntrepriseApi.readNote, options)
export const useCreateUsers = (formik: FormikProps<any>) =>
  useCustomMutationWithFormik(EntrepriseApi.createUsers, formik)

export const useDeleteUser = () => useCustomMutation(EntrepriseApi.deleteUser)
export const useResendInvitationUser = () => useCustomMutation(EntrepriseApi.resendInvitationUser)

export const useUpdateUser = (formik: FormikProps<any>) =>
  useCustomMutationWithFormik(EntrepriseApi.updateUser, formik)

  export const useGetNextFormationsQuery = (
    params: OptionalParamsApi,
    options?: OptionsTypeReactQuery<PaginationResponseSecondFormat<FormationType>>,
  ) =>
    useCustomQuery<PaginationResponseSecondFormat<FormationType>, Error>(
      [EnterprisesServerStateKeysEnum.Formations, params],
      async (header) => await EntrepriseApi.getNextFormationsEntreprise(params, header),
      options,
    )

  