import React, { useState, useContext, useMemo, useEffect } from 'react'
import SectionFormAccordion from '../../common/sectionFormAccordion'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { EditableContext } from '../../../context/editableContext'
import FormikContext from '../../../context/formikContext'
import ContactFrom from './contactFrom'
import { useFormik } from 'formik'
import { contactSchema } from '../../../validations'
import RadioButton from '../../common/fields/radioButton'
import EditButtons from '../../common/editButtons'
import { EnterprisesServerStateKeysEnum, useUpdateContact } from '../../../hooks/api/entreprises'
import { ActiveEnterpriseContext } from '../../../context/activeEnterpriseContext'
import { queryClient } from '../../..'
import { ContactType } from '../../../types/api/entreprise'
import { ContactFormType } from '../../../hooks/pages/entreprise/useEntrepriseProfile'
import { getTitle } from '../../../utils'
import { useGetPostsTitle } from '../../../hooks/api/postsTitle'
import { update } from 'ramda'
import Label from '../../common/label'
import useIsGrantedToUpdate from '../../../hooks/useIsGrantedToUpdate'

interface Props {
  contact: ContactType
  selectedPrincipalContact: number
  setSelectedPrincipalContact: React.Dispatch<React.SetStateAction<number>>
  index: number
  setDirty: React.Dispatch<React.SetStateAction<boolean[]>>
  hasMainContact: boolean
}
const getInitialValues = (data: ContactType): ContactFormType => {
  if (!data) return {} as ContactFormType
  const { contact, ...rest } = data
  const nonEmptyContacts = contact.filter((contact) => contact.phone_number !== '')
  return { mainPhone: nonEmptyContacts[0], otherPhone: nonEmptyContacts[1] || {}, ...rest }
}
const UpdateContactForm = ({
  contact,
  index,
  selectedPrincipalContact,
  setSelectedPrincipalContact,
  setDirty,
  hasMainContact,
}: Props) => {
  const { activeEnterprise } = useContext(ActiveEnterpriseContext)
  const { data: listTitles } = useGetPostsTitle()
  const [isEditable, setIsEditable] = useState(false)

  const formik = useFormik({
    initialValues: getInitialValues(contact),
    enableReinitialize: true,
    validationSchema: contactSchema,
    onSubmit: async (values, formikBag) => {
      const { mainPhone, otherPhone, crm_id, ...rest } = values
      await updateContact(
        {
          ...rest,
          contact: [mainPhone, otherPhone].filter((phone) => phone.phone_number),
          is_main_contact: selectedPrincipalContact === index,
          crm_contact_id: crm_id,
          crm_company_id: activeEnterprise.crm_id,
        },
        {
          onSuccess: async () => {
            formikBag.resetForm()
            await queryClient.invalidateQueries(EnterprisesServerStateKeysEnum.Contacts)
            setIsEditable(false)
            setSelectedPrincipalContact(0)
          },
        },
      )
    },
  })
  useEffect(() => {
    setDirty((dirty) => update(index, formik.dirty, dirty))
  }, [formik.dirty])
  const { mutate: updateContact } = useUpdateContact(formik)
  const handleReset = () => {
    if (index === selectedPrincipalContact) {
      setSelectedPrincipalContact(hasMainContact ? 0 : -1)
    }
  }
  const editableState = useMemo(() => ({ isEditable, setIsEditable }), [isEditable, setIsEditable])
  const isGrantedToUpdate = useIsGrantedToUpdate()
  return (
    <EditableContext.Provider value={editableState}>
      <FormikContext.Provider value={formik}>
        {contact?.first_name && contact?.last_name ? (
          <SectionFormAccordion
          subTitle={`${contact.first_name} ${contact.last_name} ${
            contact.is_main_contact ? ' - Contact principal' : ''
          }`}
          job={getTitle(contact.title, listTitles)}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <ContactFrom values={formik.values} />
            <Label label='Autre' sx={{ mt: '34px' }} />
            <Box sx={{ mt: '18px' }}>
              <RadioButton
                label={'Contact Principal'}
                disabled={!isEditable}
                onChange={() => setSelectedPrincipalContact(index)}
                checked={index === selectedPrincipalContact}
              />
            </Box>
            {isGrantedToUpdate && (
              <>
                <Divider sx={{ my: '24px' }} />
                <EditButtons handleReset={handleReset} />
              </>
            )}
          </Box>
        </SectionFormAccordion>
        ) : null}
        
      </FormikContext.Provider>
    </EditableContext.Provider>
  )
}

export default UpdateContactForm
